
import './default.scss';
import { defineComponent } from 'vue';

import siteModule from '@/store/site';
import LayoutMenu from '@/layouts/partials/LayoutMenu.vue';

export default defineComponent({
  name: 'DefaultLayout',
  components: { LayoutMenu },
  props: {
    width: {
      type: [Number, String],
      required: false,
    },
  },
  data: () => ({
    isMenuOpen: false,
  }),
  computed: {
    menuItems() {
      return siteModule.menuItems;
    },
  },
});
