import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "default-layout" }
const _hoisted_2 = {
  id: "id-default-layout-content",
  class: "default-layout__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layout_menu = _resolveComponent("layout-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_response_handler = _resolveComponent("response-handler")!
  const _component_response_handler_static = _resolveComponent("response-handler-static")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_layout_menu, {
      class: "default-layout__menu",
      "menu-items": _ctx.menuItems
    }, null, 8, ["menu-items"]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_router_view)
      ])
    ]),
    _createVNode(_component_response_handler),
    _createVNode(_component_response_handler_static)
  ]))
}