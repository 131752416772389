<template>
  <router-link
    v-if="item.isActive"
    :to="{ name: item.path }"
    class="layout-menu-navigation-item"
    :class="{ 'router-link-exact-active': currentRoot.indexOf(item.code) !== -1 }"
  >
    <div v-if="item.hint.length > 0" class="layout-menu-navigation-item__hint" :class="item.hintClass">
      {{ item.hint }}
    </div>

    <div class="layout-menu-navigation-item__icon-wrapper">
      <icon-font class="layout-menu-navigation-item__icon" :size="item.iconSize" :icon="item.class" />
    </div>

    <div class="layout-menu-navigation-item__name">{{ item.name }}</div>
  </router-link>
</template>

<script>
import './layoutMenuNavigationItem.scss';
import { defineComponent } from 'vue';
import IconFont from '@/components/icons/IconFont';

export default defineComponent({
  name: 'LayoutMenuNavigationItem',
  components: { IconFont },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentRoot() {
      const paths = this.$route.path.split('/');

      return paths[1] ? paths[1] : '';
    },
  },
});
</script>
