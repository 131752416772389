<template>
  <div class="layout-menu">
    <div class="layout-menu__logo">
      <img src="@/assets/images/logo-mini.svg" alt="logo" />
    </div>

    <div class="layout-menu__wrapper-scroolbar">
      <app-scrollbar class="layout-menu__scroolbar">
        <nav class="layout-menu__nav">
          <layout-menu-navigation-item v-for="(menuItem, index) in menuItems" :key="index" :item="menuItem" />
        </nav>
      </app-scrollbar>
    </div>

    <layout-menu-user class="layout-menu__user" @logOut="logOut" />
  </div>
</template>

<script>
import './layoutMenu.scss';
import { defineComponent } from 'vue';

import AuthModule from '@/store/auth';
import MenuModule from '@/store/menu';

import LayoutMenuUser from '@/layouts/partials/LayoutMenuUser';
import LayoutMenuNavigationItem from '@/layouts/partials/LayoutMenuNavigationItem';
import AppScrollbar from '@/components/ui/scroll/Scrollbar';

export default defineComponent({
  name: 'LayoutMenu',
  components: {
    AppScrollbar,
    LayoutMenuNavigationItem,
    LayoutMenuUser,
  },
  props: {
    menuItems: {
      type: Object,
      default() {
        return {
          settings: {
            name: 'Настройки',
            path: 'positions',
            code: 'settings',
            isActive: true,
            class: 'settings',
            hint: '',
            hintClass: '',
            iconSize: '20',
          },
        };
      },
    },
  },
  data() {
    return {
      isShow: true,
    };
  },

  watch: {
    $route: 'updateMenuItemsHints',
  },
  methods: {
    async logOut() {
      await AuthModule.logoutRequest();
      await this.$router.push({ name: 'login' });
    },
    updateMenuItemsHints() {
      MenuModule.updateMenuItemsHints();
    },
  },
});
</script>
