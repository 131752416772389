<template>
  <div class="layout-menu-user" :class="{ 'layout-menu-user_active': $route.name === 'profile' }">
    <icon-font class="layout-menu-user__icon" icon="user" size="19" />

    <div class="layout-menu-user__content layout-menu-user-content">
      <router-link :to="{ name: 'profile' }" class="layout-menu-user-content__profile-link">
        <icon-font class="layout-menu-user-content__icon" icon="user" size="19" />

        <span class="layout-menu-user-content__info layout-menu-user-info">
          <span class="layout-menu-user-info__name">{{ fullName }}</span>
          <span class="layout-menu-user-info__role">{{ humanRole }}</span>
        </span>
      </router-link>

      <div class="layout-menu-user-content__logout" @click="$emit('logOut')">
        <icon-font icon="logout" size="16" />
      </div>
    </div>
  </div>
</template>

<script>
import './layoutMenuUser.scss';
import { defineComponent } from 'vue';
import IconFont from '@/components/icons/IconFont';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'LayoutMenuUser',
  components: {
    IconFont,
  },
  emits: ['logOut'],
  computed: {
    ...mapState({
      fullName: (state) => state.user.fullName,
      humanRole: (state) => state.user.humanRole,
    }),
  },
});
</script>
